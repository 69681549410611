 /**
  * @author LeoStark
  * @date 2023-03-02
  * 自定义组件
  */
 import LImgUpload from './components/Leo/LImgUpload.vue'
 import LTreeSelect from './components/Leo/LTreeSelect.vue'
 import LImgViewer from './components/Leo/LImgViewer.vue'
 import print from 'vue3-print-nb'


export default {
	install(app) {
		// 注册常用组件
		app.component('LTreeSelect', LTreeSelect)
		app.component('LImgViewer', LImgViewer)
		app.component('LImgUpload', LImgUpload)
		app.use(print)
	}
}
