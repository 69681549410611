// 补充异步加载

<script>
import { TreeSelect,message } from 'ant-design-vue'
import { treeSelectProps } from 'ant-design-vue/es/tree-select/index.js'
import { h } from 'vue'
import { defineComponent, defineEmits, defineProps, onMounted, computed, reactive, watch, render } from 'vue'

export default defineComponent({
	inheritAttrs: true,
	props: {
		data: {
            type: [Function,String],
            required: true
        },
	},
	setup(props, { attrs }) {
		const emits = defineEmits(['update:value', 'change'])

		// const change = (value, label, extra) => {
		// 	emits('update:value', value)
		// }
		let treeData = ref([])
		if(!props.data){
			message.warn("请先配置data")
		}
		if(typeof props.data === 'function'){
			props.data.then((res) => {
				treeData.value = res
			})
		}else{
			request.get(props.data).then((res) => {
				treeData.value = res
			})
		}
		return () => {
			return h(TreeSelect, {
				treeData: treeData,
				fieldNames: { children: 'children', label: 'title', value: 'id' }
			})
		}
	}
})
</script>