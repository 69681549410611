<template>
	<a-config-provider :locale="locale">
		<router-view></router-view>
	</a-config-provider>
</template>

<script setup name="App">
	import i18n from '@/locales'
	import store from '@/store'
	import config from '@/config'
	import {request} from 'maple-core'
	store.commit('initTheme')
	const locale = i18n.global.messages[i18n.global.locale].lang
	let formData = ref(config.SYS_BASE_CONFIG)
	request.get('/sys/setting/get/base').then((data) => {
		formData.value = data
		store.commit('SET_SYSBASECONFIG', formData.value)
	})
</script>
